<template>
  <section>
    <Header />
    <div id="status-card" v-if="isDeliveryNotAvailable">
      <CardStatus :statusData="message" :data="data" />
    </div>
    <div id="content" class="flex" v-else>
      <Menu @location="getLocationsOnMap" @DeliveryNotAvailable="DeliveryNotAvailable" />
      <gmapMap
        ref="DmapRef"
        id="map"
        :center="oMap.center"
        :zoom="oMap.zoom"
        style="height: 100%"
        :options="mapStyle"
      >
        <gmap-custom-marker
          v-for="(marker, index) in markers"
          :key="`marker-${index}`"
          :marker="marker.position"
        >
          <IconMarker :icon="marker.icon" :color="marker.color" />
        </gmap-custom-marker>
      </gmapMap>
    </div>
  </section>
</template>

<script>
import { lightMapStyle } from '@/utils/MapStyles';
import {
  trackingCanceled,
  trackingDelivered,
  trackingError,
  trackingFailed,
  trackingRejected,
  trackingRescheduled
} from './TrackingData';
import { map_apiKey } from '@/config/constants.js';
import * as VueGoogleMaps from 'vue2-google-maps';
import GmapCustomMarker from 'vue2-gmap-custom-marker';
import CardStatus from '@/components/Tracking/CardStatus.vue';
import Vue from 'vue';
import { IconMarker } from '@/components';
Vue.use(VueGoogleMaps, { load: { key: map_apiKey } });
import Header from './Header';
import Menu from './Menu';
export default {
  components: {
    IconMarker,
    GmapCustomMarker,
    Header,
    Menu,
    CardStatus
  },
  async mounted() {
    this.map = await this.$refs.DmapRef.$mapPromise;
  },
  data() {
    return {
      data: {},
      isDeliveryNotAvailable: false,
      loading: false,
      isAutozoomAllowed: true,
      isHidden: true,
      map: null,
      markers: [],
      message: {},
      mapStyle: { styles: lightMapStyle },
      oMap: { center: { lat: 32.6239586, lng: -117.066604 }, zoom: 8 },
      trackingCanceled,
      trackingDelivered,
      trackingError,
      trackingFailed,
      trackingRejected,
      trackingRescheduled
    };
  },
  computed: {
    hasData() {
      return this.data;
    }
  },
  methods: {
    async getLocationsOnMap(args) {
      const { map, isAutozoomAllowed } = this;
      const { current_lat, current_lng, destination_lat, destination_lng } = args;
      if (!map || !current_lat || !current_lng || !destination_lat || !destination_lng) return;

      this.markers = [
        { position: { lat: current_lat, lng: current_lng }, icon: 'car' },
        {
          position: { lat: destination_lat, lng: destination_lng },
          color: '86cc8d',
          icon: 'home'
        }
      ];
      if (map && isAutozoomAllowed) {
        this.isAutozoomAllowed = false;
        let latlngbounds = new window.google.maps.LatLngBounds();
        this.markers.map(
          (e) => (latlngbounds = this.maplatLngExtendPath(latlngbounds, e.position))
        );
        map.fitBounds(latlngbounds);
        let currentZoom = map.getZoom();
        if (currentZoom > 18) {
          currentZoom = 18;
          map.setZoom(18);
        }
      }
    },
    maplatLngExtendPath(latlngbounds, paths) {
      if (paths.lat) return latlngbounds.extend(paths);
    },
    async DeliveryNotAvailable(type, data) {
      this.data = data;

      switch (type) {
        case 'Error':
          this.message = this.trackingError;
          break;
        case 'Rejected':
          this.message = this.trackingRejected;
          break;
        case 'Re Scheduled':
          this.message = this.trackingRescheduled;
          break;
        case 'Canceled':
          this.message = this.trackingCanceled;
          break;
        case 'Failed':
          this.message = this.trackingFailed;
          break;
        case 'Delivered':
          this.message = this.trackingDelivered;
          break;
        default:
          this.message = this.trackingError;
      }
      this.isDeliveryNotAvailable = true;
    }
  }
};
</script>
<style lang="sass" scoped>
#content
  height: calc(100vh - 80px)
#map
  width: 100%
  height: 100%
#status-card
  display: flex
  height: calc(100vh - 80px)
  width: 100%
  align-items: center
  justify-content: center
  background: $main-background
  .card-status
    width: 600px
    min-height: 615px
    display: flex
    justify-content: center
    align-items: center !important
    padding: 0 4rem
    color: $black
    text-align: center
</style>
