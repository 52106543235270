<template>
  <div class="tracking-card">
    <Card class="driver-arriving main-container" :hasMinHeight="false">
      <div class="flex">
        <div class="icon-content">
          <b-icon icon="clock-outline" />
        </div>
        <div class="arriving-content flex">
          <div class="title-arriving">
            {{ $t('dispatch.driverArriving') }}
          </div>
          <div class="time-left">{{ formatSeconds(delivery.vehicle_status_time) }} Mins</div>
        </div>
      </div>
    </Card>
    <div class="step-card">
      <TrackingStepCard
        icon="account"
        :title="$t('dispatch.driverName')"
        :text="delivery.driver_name"
      />
      <div class="flex f-jsb vehicle-card">
        <TrackingStepCard
          class="w-100"
          icon="car-estate"
          :title="$t('run.vehicle')"
          :text="delivery.vehicle_make"
        />
        <TrackingStepCard
          class="w-100"
          :title="$t('global.color')"
          :text="delivery.vehicle_color"
        />
      </div>
      <TrackingStepCard
        icon="card-account-details"
        :title="$tc('vehicle.license', 1)"
        :text="delivery.vehicle_license_plate_number"
      />
      <TrackingStepCard
        class="final-card"
        icon="account-tie"
        :title="$t('dispatch.provider')"
        :text="delivery.provider_name"
      />
    </div>
  </div>
</template>

<script>
import TrackingStepCard from '@/components/Dispatch/Tracking/TrackingStepCard.vue';
import { Card } from '@/components';
export default {
  components: {
    TrackingStepCard,
    Card
  },
  methods: {
    formatSeconds(time) {
      if (!time) return '-';
      const minutes = Math.ceil(time / 60);
      return minutes;
    }
  },
  props: {
    delivery: { type: Object, default: () => {} }
  }
};
</script>

<style lang="sass" scoped>
.card
  margin: 0

.main-container
  border-radius: 8px 8px 0px 0px

.final-card
  border-radius: 0px 0px 8px 8px

.driver-arriving
  padding: 0
  padding-left: 20px
  background: $blue-400
  color: white
  font-weight: bold
  .icon-content
    margin-right: 11px
    .icon
      width: 30px
      height: 30px
.arriving-content
  padding-top: 3px
  font-size: $f-lg
.time-left
  width: 170px
  padding-right: 10px
  text-align: right
  font-size: $f-xl

@media screen and (max-width: $bp-sm)
  .arriving-content
    flex-flow: column
    .time-left
      text-align: left
      font-size: $f-lg
  .step-card
    .main-card
      ::v-deep
        .card-content
          .icon-container
            padding: 0
    .vehicle-card
      flex-flow: column
</style>
