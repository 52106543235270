<template>
  <Card class="p-card-il" :hasMinHeight="false">
    <div class="details-content flex">
      <div class="icon-container">
        <b-icon :icon="icon" />
      </div>
      <div class="details flex">
        <div class="header">
          <span>{{ text }}</span>
        </div>
        <div v-if="!isVehicle && !isTime" class="status">
          <strong>{{ status }}</strong>
        </div>
        <div v-else-if="isVehicle" class="status">
          <strong>{{ distance(status) }} mi</strong>
        </div>
        <div v-else class="status">
          <strong><DateTime :date="status" :offset="offset" format="hh:mm A" /></strong>
        </div>
      </div>
    </div>
  </Card>
</template>

<script>
import { Card, DateTime } from '@/components';
import { MetersToMiles } from '@/utils/Metrics';

export default {
  components: {
    Card,
    DateTime
  },
  methods: {
    distance(metters) {
      return MetersToMiles(metters);
    }
  },
  props: {
    icon: { type: String },
    text: { type: String },
    status: { type: String },
    isVehicle: { type: Boolean, default: false },
    isTime: { type: Boolean, default: false },
    offset: { type: Number, default: null }
  }
};
</script>

<style lang="sass" scoped>
.card
  padding: 0
  color: $gray-700
  strong
    color: $gray-700

.details-content
  padding-right: 18px
  padding-left: 18px
  .icon-container
    .icon
      color: $blue-500
      margin-right: 18px
      padding-top: 10px
.details
  width: 100%
  .header
    padding-top: 7px
    width: 80%
.status
  width: 100%
  text-align: right
  padding-top: 7px
  .flex
    margin-left: 72px

@media screen and (max-width: $bp-sm)
  .details-content
    .details
      flex-flow: column
    .status
      text-align: left
      .flex
        margin-left: 0px
    .icon-container
      .icon
        padding-top: 30px
</style>
