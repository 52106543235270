<template>
  <Card class="p-card-il main-card" :hasMinHeight="false">
    <div class="tracking-content flex">
      <div class="icon-container">
        <b-icon class="icon-dropdown" :icon="icon" />
      </div>
      <div class="tracking-content">
        <strong>{{ title }}</strong> <br />
        <span>{{ text }}</span>
      </div>
    </div>
  </Card>
</template>

<script>
import { Card } from '@/components';
export default {
  components: {
    Card
  },
  data() {
    return {};
  },
  props: {
    icon: { type: String, require: true },
    title: { type: String, require: true },
    text: { type: String, require: true }
  }
};
</script>

<style lang="sass" scoped>
.card
  padding: 0
  color: $gray-700
  strong
    color: $gray-700
.main-card
  border-radius: 0
.icon-container
  margin-left: 20px
  margin-right: 20px
  padding-top: 12px
  .icon
    color: $blue-500
</style>
