<template>
  <div :class="isActive ? 'rx-info' : 'rx-info-hidden'">
    <nav>
      <b-skeleton v-if="loading" width="100%" height="365" />
      <TrackingCard v-else :delivery="delivery" />
      <div class="details-container">
        <strong> {{ $tc('global.details', 2) }} </strong>
        <div v-if="loading">
          <b-skeleton v-for="i in 3" width="100%" height="61" :key="i" />
        </div>
        <div v-else>
          <DetailsCard
            icon="information-outline"
            :text="$t('dispatch.eventType')"
            :status="delivery.event_type"
          />
          <DetailsCard
            icon="car"
            :text="$t('schedule.manifest.distance')"
            :status="String(delivery.vehicle_status_distance || '')"
            :isVehicle="true"
          />
          <!-- <DetailsCard
            icon="timer-sand-empty"
            text="ETA"
            :status="delivery.vehicle_status_time"
            :isTime="true"
          /> -->
          <DetailsCard
            icon="timelapse"
            :text="$t('dispatch.scheduledTime')"
            :status="delivery.scheduled_time"
            :isTime="true"
            :offset="delivery.time_offset"
          />
        </div>
      </div>
    </nav>
    <button
      v-if="!loading"
      @click="activeMenu"
      :class="
        isActive ? 'button display-button show-menu-btn' : 'button display-button hide-menu-btn'
      "
    >
      <b-icon :icon="isActive ? 'close' : 'menu'" />
    </button>
  </div>
</template>

<script>
import axios from 'axios';
import { apiEndPoint } from '@/config/constants.js';
import TrackingCard from '@/components/Dispatch/Tracking/TrackingCard.vue';
import DetailsCard from '@/components/Dispatch/Tracking/DetailsCard.vue';

export default {
  components: {
    TrackingCard,
    DetailsCard
  },
  async created() {
    this.ax = axios.create({
      baseURL: apiEndPoint,
      headers: { Authorization: new Date().toDateString() }
    });
    this.loading = true;
    this.getRxUrl();
  },
  data() {
    const paramsRxToken = this.$route.params.trackingHashId;
    return {
      ax: null,
      paramsRxToken,
      delivery: {},
      isActive: true,
      loading: false,
      timeout: 0
    };
  },
  methods: {
    activeMenu() {
      if (this.isActive) {
        this.isActive = false;
        return this.isActive;
      } else if (!this.isActive) {
        this.isActive = true;
        return this.isActive;
      }
    },
    async getRxUrl() {
      clearTimeout(this.timeout);
      try {
        const { data } = await this.ax.get(
          `/delivery_manifest/where_is_my_rx?token=${this.paramsRxToken}`
        );

        if (data.data.event_type === 'In Progress') {
          this.delivery = data.data;
          this.$emit('location', {
            current_lat: this.delivery.current_position_latitude,
            current_lng: this.delivery.current_position_longitude,
            destination_lat: this.delivery.destination_latitude,
            destination_lng: this.delivery.destination_longitude
          });
          this.timeout = setTimeout(() => {
            this.getRxUrl();
          }, 10000);
        } else {
          this.$emit('DeliveryNotAvailable', data.data.event_type, data.data);
        }
      } catch (error) {
        this.$emit('DeliveryNotAvailable', 'Error', {});
      }
      this.loading = false;
    }
  },
  watch: {
    loading(val) {
      this.$emit('update:loading', val);
    }
  }
};
</script>

<style lang="sass" scoped>
.rx-info
  z-index: 2
  box-shadow: 0px 8px 24px rgba(24, 56, 153, 0.12)
  nav
    background: $main-background
    height: 100%
    left: 0px
    overflow-y: auto
    padding: 24px
    position: relative
    transition: 500ms ease
    width: 400px
.rx-info-hidden
  z-index: 2
  nav
    width: 400px
    height: 100%
    background: $main-background
    padding: 24px
    position: absolute
    left: -400px
    transition: 500ms ease
.card
  margin-right: 0
  margin-left: 0
.details-container
  margin-top: 24px

.display-button
  border: none
  border-radius: 0px 8px 8px 0px
  box-shadow: 0px 8px 24px rgba(24, 56, 153, 0.12)
.show-menu-btn
  position: absolute
  top: 140px
  left: 400px
  transition: 500ms ease
  z-index: 2
  &:focus
    box-shadow: none
.hide-menu-btn
  border-radius: 0px 8px 8px 0px
  position: absolute
  top: 140px
  left: 0px
  transition: 500ms ease
  z-index: 2
  &:focus
    box-shadow: none

@media screen and (max-width: $bp-sm)
  .rx-info
    nav
      width: 320px
  .show-menu-btn
    left: 320px

@media screen and (max-width: 390px)
  .rx-info
    nav
      width: 240px
  .show-menu-btn
    left: 240px
</style>
