<template>
  <div id="header">
    <div id="logo">
      <img src="@/assets/img/logo-light.svg" alt="CFS logo" />
    </div>
    <div id="title">{{ $t('traking.title') }}</div>
  </div>
</template>

<script>
export default {
  created() {},
  methods: {},
  computed: {}
};
</script>

<style lang="sass" scoped>
#header
  width: 100%
  height: 80px
  display: flex
  padding: 0 40px
  align-items: center
  background: white
  color: $dark
  #logo
    margin-right: 20px
    width: 100px
#title
  font-weight: bold
  font-size: $f-xxl
</style>
