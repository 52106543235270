<template>
  <Card class="card-status">
    <div class="card-status-container">
      <div class="card-status-error-title">{{ $t(`${statusData.title}`) }}</div>
      <b-image
        class="card-status-b-image"
        :src="require(`@/assets/img/tracking/${statusData.url}`)"
      />
      <div v-if="statusData.description.initialMessage">
        <p class="card-status-error-message">
          {{ $t(`${statusData.description.initialMessage}`) }}
        </p>
        <p v-if="statusData.description.recipientName">
          {{ $t(`${statusData.description.recipientName}`) }}
          <span> {{ data.receptor_name }} </span>
        </p>
        <div v-if="statusData.description.date">
          <DateTime
            class="card-status-date-time"
            :date="data.timestamp"
            :offset="data.time_offset"
            :label="$t(`${statusData.description.date}`)"
          />
        </div>
        <div v-if="statusData.description.prevDate">
          <DateTime
            class="card-status-date-time"
            :date="data.previous_timestamp"
            :offset="data.time_offset"
            :label="$t(`${statusData.description.prevDate}`)"
          />
          <DateTime
            class="card-status-date-time"
            :date="data.scheduled_time"
            :offset="data.time_offset"
            :label="$t(`${statusData.description.newDate}`)"
          />
        </div>
        <p v-if="statusData.description.reason">
          {{ $t(`${statusData.description.reason}`) }}
          <span> {{ data.reason }} </span>
        </p>
        <p class="card-status-final-message">
          {{ $t(`${statusData.description.finalMessage}`) }}
        </p>
      </div>
      <p class="card-status-error-message" v-else>
        {{ $t(`${statusData.description}`) }}
      </p>
    </div>
  </Card>
</template>

<script>
import { DateTime } from '@/components';
import Card from '@/components/Card.vue';

export default {
  components: {
    Card,
    DateTime
  },
  props: {
    statusData: {
      type: Object,
      default: () => {}
    },
    data: {
      type: Object,
      default: () => {}
    }
  }
};
</script>

<style lang="sass" scoped>
.card-status
  &-container
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    font-weight: 500
    span
      font-weight: 700
  &-b-image
    width: 294px
  &-error-title
    font-size: 30px
    font-weight: 700
    line-height: 120%
    margin-bottom: 1.5rem
  &-error-message
    margin-top: 1.5rem
  &-final-message
    margin-top: 1.5rem
  ::v-deep
    .card-content
      height: auto
  &-date-time
    display: flex
    justify-content: center
    ::v-deep
      span:nth-child(2)
        font-weight: 700 !important
</style>
