export const trackingDelivered = {
  title: 'tracking.delivered.title',
  url: 'tracking-delivered.png',
  description: {
    initialMessage: 'tracking.delivered.description.initialMessage',
    recipientName: 'tracking.recipientName',
    date: 'tracking.dateTime',
    finalMessage: 'tracking.delivered.description.finalMessage'
  }
};

export const trackingRejected = {
  title: 'tracking.rejected.title',
  url: 'tracking-failed.png',
  description: {
    initialMessage: 'tracking.rejected.description.initialMessage',
    recipientName: 'tracking.recipientName',
    reason: 'tracking.reason',
    finalMessage: 'tracking.inconvenience'
  }
};

export const trackingCanceled = {
  title: 'tracking.canceled.title',
  url: 'tracking-failed.png',
  description: {
    initialMessage: 'tracking.canceled.description.initialMessage',
    date: 'tracking.dateTime',
    reason: 'tracking.reason',
    finalMessage: 'tracking.inconvenience'
  }
};

export const trackingFailed = {
  title: 'tracking.failed.title',
  url: 'tracking-failed.png',
  description: {
    initialMessage: 'tracking.failed.description.initialMessage',
    date: 'tracking.dateTime',
    finalMessage: 'tracking.inconvenience'
  }
};

export const trackingRescheduled = {
  title: 'tracking.rescheduled.title',
  url: 'tracking-rescheduled.png',
  description: {
    initialMessage: 'tracking.rescheduled.description.initialMessage',
    prevDate: 'tracking.rescheduled.description.prevDate',
    newDate: 'tracking.rescheduled.description.newDate',
    reason: 'tracking.reason',
    finalMessage: 'tracking.inconvenience'
  }
};

export const trackingError = {
  title: 'tracking.error.title',
  url: 'tracking-error.png',
  description: 'tracking.error.description'
};
